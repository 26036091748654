@import "./fonts.css";
html,
body {
  padding: 0;
  margin: 0;
}
body {
  padding-top: 66px;
  font-size: 12px !important;
  font-family: Montserrat, sans-serif !important;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}
